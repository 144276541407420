import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hero-page container mt-4"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-sm-4 text-center mb-4" }
const _hoisted_4 = { class: "hero-header" }
const _hoisted_5 = { class: "hero-name" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "hero-description"
}
const _hoisted_8 = { class: "col-12 col-sm-8 mb-4" }
const _hoisted_9 = { class: "hero-stats m-3 m-lg-0" }
const _hoisted_10 = { class: "stat" }
const _hoisted_11 = { class: "progress" }
const _hoisted_12 = { class: "stat" }
const _hoisted_13 = { class: "progress" }
const _hoisted_14 = { class: "stat" }
const _hoisted_15 = { class: "progress" }
const _hoisted_16 = { class: "stat" }
const _hoisted_17 = { class: "progress" }
const _hoisted_18 = { class: "stat" }
const _hoisted_19 = { class: "progress" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-xl-4 col-12 mb-4" }
const _hoisted_22 = { class: "hero-equipment" }
const _hoisted_23 = { class: "col-xl-8 col-12" }
const _hoisted_24 = { class: "hero-inventory" }

import {computed, onMounted, ref} from "vue";
import {useInventoryStore} from "../store/inventory";
import {usePlayerStore} from "../store/player";

import heroImgMale from '@assets/imgs/hero_m.webp';
import heroImgFemale from '@assets/imgs/hero_f.webp';
import heroImgNone from '@assets/imgs/hero_n.webp';
import {heroSex} from "../type";
import PlayerInventory from "../components/inventory/PlayerInventory.vue";
import PlayerEquipment from "../components/inventory/PlayerEquipment.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeroView',
  setup(__props) {


const playerStore = usePlayerStore();
const inventoryStore = useInventoryStore();

const hero = computed(() => {
	return playerStore.player?.hero;
});

const statsProgress = computed(() => {
	return {
		health: (hero.value?.health / (hero.value?.max_health / 100)) || 0,
		agility: hero.value?.agility || 0,
		strength: hero.value?.strength || 0,
		defense: hero.value?.defense || 0,
		experiences: hero.value?.experiences || 0,
	};
});

const heroImg = computed(() => {
	if (!playerStore.player?.hero) {
		return undefined;
	}

	if (playerStore.player.hero.sex === heroSex.MALE) {
		return heroImgMale;
	} else if (playerStore.player.hero.sex === heroSex.FEMALE) {
		return heroImgFemale;
	}

	return heroImgNone;
});

onMounted(() => {
	inventoryStore.fetchItems();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[8] || (_cache[8] = _createElementVNode("h1", null, "Hrdina", -1)),
    (hero.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h1", _hoisted_5, _toDisplayString(hero.value.name), 1),
                _createElementVNode("img", {
                  src: heroImg.value,
                  alt: "Hero Portrait",
                  class: "hero-portrait"
                }, null, 8, _hoisted_6),
                _createElementVNode("p", null, [
                  _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Lvl", -1)),
                  _createTextVNode(" " + _toDisplayString(hero.value.level), 1)
                ]),
                (hero.value.description)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(hero.value.description), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Statistiky", -1)),
                _createElementVNode("div", _hoisted_10, [
                  _cache[1] || (_cache[1] = _createElementVNode("span", null, "Zdraví", -1)),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", {
                      class: "progress-bar bg-danger",
                      role: "progressbar",
                      style: _normalizeStyle({width: statsProgress.value.health+'%'})
                    }, _toDisplayString(hero.value.health), 5)
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[2] || (_cache[2] = _createElementVNode("span", null, "Obratnost", -1)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", {
                      class: "progress-bar bg-success",
                      role: "progressbar",
                      style: _normalizeStyle({width: statsProgress.value.agility+'%'})
                    }, _toDisplayString(hero.value.agility), 5)
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _cache[3] || (_cache[3] = _createElementVNode("span", null, "Síla", -1)),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", {
                      class: "progress-bar bg-primary",
                      role: "progressbar",
                      style: _normalizeStyle({width: statsProgress.value.strength+'%'})
                    }, _toDisplayString(hero.value.strength), 5)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", null, "Obrana", -1)),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", {
                      class: "progress-bar bg-primary",
                      role: "progressbar",
                      style: _normalizeStyle({width: statsProgress.value.defense+'%'})
                    }, _toDisplayString(hero.value.defense), 5)
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("span", null, "Další úrověň (" + _toDisplayString(hero.value.experiences_next_level) + ")", 1),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", {
                      class: "progress-bar bg-primary",
                      role: "progressbar",
                      style: _normalizeStyle({width: statsProgress.value.experiences+'%'})
                    }, _toDisplayString(hero.value.experiences), 5)
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Vybavení", -1)),
                _createVNode(PlayerEquipment)
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Inventář", -1)),
                _createVNode(PlayerInventory)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
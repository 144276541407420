import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "navbar navbar-expand-lg bg-light" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "navbar-brand" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "m-0 me-auto" }
const _hoisted_6 = { class: "list-inline-item d-lg-none d-inline" }
const _hoisted_7 = {
  key: 0,
  class: "list-inline-item d-lg-none d-inline"
}
const _hoisted_8 = {
  class: "collapse navbar-collapse",
  id: "mainMenu"
}
const _hoisted_9 = {
  key: 0,
  class: "navbar-nav me-auto mb-2 mb-lg-0 d-flex"
}
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "nav-item" }
const _hoisted_13 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_14 = {
  key: 1,
  class: "navbar-nav navbar-right"
}
const _hoisted_15 = { class: "nav-item" }
const _hoisted_16 = { class: "nav-link text-black" }
const _hoisted_17 = { class: "nav-item" }
const _hoisted_18 = { class: "container flex-grow-1 p-0 p-3" }
const _hoisted_19 = { class: "container" }
const _hoisted_20 = {
  key: 0,
  class: "alert alert-primary alert-dismissible fade show d-flex align-items-center",
  role: "alert"
}

import {onMounted, watch} from "vue";
import router from "./router";
import * as Sentry from "@sentry/vue";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

import logo from '@assets/imgs/logo.webp';

import InventoryModal from "./components/inventory/InventoryModal.vue";

import SignService from "./common/sign.service";

import { useSignStore } from "./store/sign";
import { usePlayerStore } from "./store/player";
import { useMapStore } from "./store/map";
import { useInventoryStore } from "./store/inventory";


export default /*@__PURE__*/_defineComponent({
  __name: 'Game',
  setup(__props) {


const signStore = useSignStore();
const playerStore = usePlayerStore();
const mapStore = useMapStore();
const inventoryStore = useInventoryStore();

watch(() => signStore.authenticated, (authenticated) => {
	if (authenticated === false) {
		router.push({name: 'sign'});
	}
});

watch(() => playerStore.player, (player) => {
	if (player) {
		Sentry.setUser(player);
	}
});

onMounted(() => {
	if (signStore.authenticated) {
		playerStore.fetchPlayer();
	} else {
		router.push({name: 'sign'});
	}

	if (playerStore.player) {
		Sentry.setUser(playerStore.player);
	}
});


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", null, [
      _createElementVNode("nav", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _unref(logo),
              alt: "Kokume",
              width: "32",
              height: "32",
              class: "d-inline-block"
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("ul", _hoisted_5, [
            _createElementVNode("li", _hoisted_6, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(mapStore).state = _unref(mapStore).state !== 'build' ? 'build' : 'none')),
                class: _normalizeClass(["btn btn-sm", _unref(mapStore).state === 'build' ? 'btn-success' : 'btn-outline-secondary'])
              }, " 🏗️ Stavět ", 2)
            ]),
            (_unref(playerStore).player?.hero)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(inventoryStore).open = !_unref(inventoryStore).open)),
                    class: _normalizeClass(["btn btn-sm", _unref(inventoryStore).open ? 'btn-success' : 'btn-outline-secondary'])
                  }, " 💼 Inventář ", 2)
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("button", {
            class: "navbar-toggler",
            type: "button",
            "data-bs-toggle": "collapse",
            "data-bs-target": "#mainMenu",
            "aria-controls": "mainMenu",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation"
          }, [
            _createElementVNode("span", { class: "navbar-toggler-icon" })
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            (_unref(signStore).authenticated)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                  _createElementVNode("li", _hoisted_10, [
                    _createVNode(_component_RouterLink, {
                      to: "/",
                      class: "nav-link"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("Domů")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_11, [
                    _createVNode(_component_RouterLink, {
                      to: "/dashboard",
                      class: "nav-link"
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Přehled")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_12, [
                    _createVNode(_component_RouterLink, {
                      to: "/map",
                      class: "nav-link"
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Mapa")
                      ])),
                      _: 1
                    })
                  ]),
                  (_unref(playerStore).player?.hero)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                        _createVNode(_component_RouterLink, {
                          to: "/hero",
                          class: "nav-link"
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("Hrdina")
                          ])),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_unref(signStore).authenticated)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_14, [
                  _createElementVNode("li", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, [
                      _createElementVNode("strong", null, _toDisplayString(_unref(playerStore).player?.nickname), 1)
                    ])
                  ]),
                  _createElementVNode("li", _hoisted_17, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "nav-link",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_unref(SignService).signOut()), ["prevent"]))
                    }, [
                      _createVNode(_unref(FontAwesomeIcon), { icon: _unref(faSignOut) }, null, 8, ["icon"]),
                      _cache[7] || (_cache[7] = _createTextVNode(" Odhlásit se"))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("main", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        (!_unref(playerStore).player?.hero)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[9] || (_cache[9] = [
              _createTextVNode(" V hlavní budově si můžeš najmout hrdinu. Díky němu můžeš sbírat předměty a bojovat s nepřáteli. "),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "alert",
                "aria-label": "Close"
              }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_RouterView),
      (_unref(playerStore).player?.hero)
        ? (_openBlock(), _createBlock(InventoryModal, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "map-container" }
const _hoisted_2 = { class: "navbar map-navbar" }
const _hoisted_3 = { class: "list-unstyled list-inline" }
const _hoisted_4 = { class: "list-inline-item" }
const _hoisted_5 = { class: "list-inline-item" }
const _hoisted_6 = { class: "list-inline-item d-none d-lg-inline" }
const _hoisted_7 = {
  key: 0,
  class: "list-inline-item d-none d-lg-inline"
}
const _hoisted_8 = {
  key: 1,
  class: "list-inline-item"
}
const _hoisted_9 = { class: "badge bg-secondary" }
const _hoisted_10 = {
  key: 0,
  class: "resources-container"
}
const _hoisted_11 = { class: "list-unstyled list-inline" }
const _hoisted_12 = ["title"]
const _hoisted_13 = { class: "badge bg-opacity-75 bg-secondary m-1" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { key: 3 }
const _hoisted_18 = { key: 4 }
const _hoisted_19 = {
  key: 1,
  class: "alert alert-danger"
}

import 'leaflet/dist/leaflet.css';

import L, {LatLng, Marker, Point} from 'leaflet';
import {LCircle, LGeoJson, LMap, LPopup, LTileLayer} from '@vue-leaflet/vue-leaflet';
import {onMounted, onUnmounted, ref, watch} from "vue";
import {debounce} from 'lodash';
import BuildModal from "../components/BuildModal.vue";

import {storeToRefs} from "pinia";

import {useMapStore} from "../store/map";
import {usePlayerStore} from "../store/player";
import {useRegionStore} from "../store/region";
import {useBuildingStore} from "../store/building";
import {useInventoryStore} from "../store/inventory";

import baseIcon from '@assets/imgs/icons/base.svg';
import subBaseIcon from '@assets/imgs/icons/sub_base.svg';
import farmIcon from '@assets/imgs/icons/farm.svg';
import fishingHutIcon from '@assets/imgs/icons/fishing_hut.svg';
import mineIcon from '@assets/imgs/icons/mine.svg';
import woodcuttersIcon from '@assets/imgs/icons/woodcutters.svg';
import quarryIcon from '@assets/imgs/icons/stones.svg';
import infoIcon from '@assets/imgs/icons/info.svg';
import itemIcon from '@assets/imgs/icons/treasure.svg';

import GeoLocationService from "../common/geolocation.service";
import MapInfoModal from "../components/MapInfoModal.vue";

const url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const attribution = 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';

export default /*@__PURE__*/_defineComponent({
  __name: 'MapView',
  setup(__props) {

const map = ref<typeof LMap | undefined>(undefined);
const gjLayer = ref<typeof LGeoJson | undefined>(undefined);

const mapStore = useMapStore();

const regionStore = useRegionStore();
const buildingStore = useBuildingStore();
const playerStore = usePlayerStore();
const inventoryStore = useInventoryStore();

const { zoom, bounds, state } = storeToRefs(mapStore);

const lockPosition = ref(false);

const fakePosActive = ref(false);
const fakePos = ref<LatLng | undefined>(undefined);
const fakePosInterval = ref<NodeJS.Timeout | undefined>(undefined);

function mapClicked(event: any) {
	if (state.value === 'none') {
		showInfoModal(event.layer);
	}
	else if (state.value === 'fake_pos') {
		fakePos.value = event.latlng;
		state.value = 'none';
	}
	else if (state.value === 'build_preview') {
		state.value = 'build';
	}
	else if (state.value === 'build_position') {
		state.value = 'build';
	}
}

function showInfoModal(layer: any) {
	mapStore.selectedFeature = layer.feature;
}

function centerToPos() {
	if (playerStore.position && map.value) {
		map.value.leafletObject.setView(playerStore.position.latLng);
	}
}

function reloadPage() {
	location.reload();
}

function setGeoJSONStyle(feature: any, layer: any) {
	if (feature.geometry.type === 'Polygon') {
		const styles = {
			color: '#000000',
			fillColor: '#cecece',
			weight: 1.0,
		};

		const owner = feature.properties.owner;
		const type = feature.properties.type;

		if (type === 'resource') {
			styles.color = '#c124fa';
			styles.weight = 2.0;

			const resource = feature.properties.resource;
			switch (resource) {
				case 'forest':
					styles.fillColor = '#00ff00';
					break;
				case 'meadow':
					styles.fillColor = '#00ff00';
					break;
				case 'field':
					styles.fillColor = '#ffff00';
					break;
				case 'quarry':
				case 'mine':
					styles.fillColor = '#646469';
					break;
				case 'water':
				case 'reservoir':
				case 'pond':
					styles.fillColor = '#0000ff';
					break;
			}
		} else if (owner) {
			styles.fillColor = owner.id === playerStore.player?.id ? '#20b720' : '#b72020';
		}

		return styles;
	}
}

function pointToLayer(feature: any, latlng: any) {
	const type = feature.properties.type;

	if (type === 'building') {
		const buildingType = feature.properties.building_type;

		if (buildingType === 'base') {
			return new Marker(latlng, {
				icon: L.icon({
					iconUrl: baseIcon,
					iconSize: new Point(40, 40),
					iconAnchor: new Point(10, 10),
				}),
			});
		}

		const icon = {
			'sub_base': subBaseIcon,
			'farm': farmIcon,
			'fishing_hut': fishingHutIcon,
			'iron_mine': mineIcon,
			'clay_mine': mineIcon,
			'sawmill': woodcuttersIcon,
			'quarry': quarryIcon,
		}[buildingType];

		return new Marker(latlng, {
			icon: L.icon({
				iconUrl: icon,
				iconSize: [30, 30],
				iconAnchor: [10, 10],
			}),
		});
	}

	if (type === 'item') {
		return new Marker(latlng, {
			icon: L.icon({
				iconUrl: itemIcon,
				iconSize: [25, 25],
				iconAnchor: [10, 10],
			}),
		})
	}

	return new Marker(latlng);
}

function occupyRegion() {
	regionStore.occupyCurrentRegion()
}

function toggleFakePos(pos: LatLng | undefined = undefined) {
	if (pos) {
		fakePos.value = pos;
		lockPosition.value = true;

		const playerPos = {latLng: pos, accuracy: 10, speed: 0};
		playerStore.position = playerPos;
		playerStore.update(playerPos);
	} else {
		state.value = fakePosActive.value ? 'none' : 'fake_pos';
	}

	fakePosActive.value = !fakePosActive.value;

	if (fakePosActive) {
		GeoLocationService.clearWatch();
	} else {
		GeoLocationService.watchGeolocation();
	}
}

function updateMapData() {
	updateMapDataDebounced();
}

const updateMapDataDebounced = debounce(() => {
	mapStore.fetchData();
}, 1000);

watch([zoom, bounds], () => {
	updateMapData();
});

watch(() => fakePosActive.value, (newValue) => {
	if (newValue) {
		if (fakePosInterval.value) {
			return;
		}

		fakePosInterval.value = setInterval(() => {
			if (!fakePos.value) {
				return;
			}

			playerStore.update({
				latLng: fakePos.value,
				accuracy: Math.round(Math.random() * 20),
				speed: Math.round(Math.random() * 5),
			});
		}, 5000);
	} else {
		if (fakePosInterval.value) {
			clearInterval(fakePosInterval.value);
			fakePosInterval.value = undefined;
		}
	}
});

// center to player position after map is loaded
watch(map, (newValue) => {
	if (newValue) {
		setTimeout(centerToPos, 500);
	}
});

watch(fakePos, (newValue) => {
	if (newValue?.lat && newValue?.lng) {
		document.cookie = `fake_pos=${newValue.lat},${newValue.lng}`;
	} else {
		document.cookie = 'fake_pos=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
	}
});

onMounted(() => {
	if (buildingStore.buildings === undefined) {
		buildingStore.fetchBuildings();
	}

	GeoLocationService.watchGeolocation();

	setInterval(() => {
		if (lockPosition.value) {
			centerToPos();
		}
	}, 500);

	let lastPosition: LatLng | undefined;
	setInterval(() => {
		if (lockPosition.value && playerStore.position && playerStore.position.latLng && lastPosition !== playerStore.position.latLng) {
			mapStore.fetchData();
			lastPosition = playerStore.position.latLng;
		}
	}, 2000);

	// load fake position from cookie
	const fakePosCookie = document.cookie.split('; ').find(row => row.startsWith('fake_pos='));
	if (fakePosCookie) {
		const [lat, lng] = fakePosCookie.split('=')[1].split(',');
		if (parseFloat(lat) && parseFloat(lng)) {
			toggleFakePos(new LatLng(parseFloat(lat), parseFloat(lng)));
		}
	}
});

onUnmounted(() => {
	GeoLocationService.clearWatch();
	mapStore.geoJson = undefined;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (lockPosition.value = !lockPosition.value)),
            class: _normalizeClass(["btn btn-sm", 'btn' + (lockPosition.value ? '-success' : '-secondary')]),
            title: "Uzamknout polohu",
            "aria-label": "Uzamknout polohu"
          }, " 🧭 ", 2)
        ]),
        _createElementVNode("li", _hoisted_5, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleFakePos())),
            class: _normalizeClass(["btn btn-sm", 'btn' + (fakePosActive.value ? '-success' : '-secondary')]),
            title: "Fejkovat polohu"
          }, " 👇🏼 Fake ", 2)
        ]),
        _createElementVNode("li", _hoisted_6, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (state.value = _unref(state) !== 'build' ? 'build' : 'none')),
            class: _normalizeClass(["btn btn-sm", 'btn' + (_unref(state) === 'build' ? '-success' : '-secondary')])
          }, " 🏗️ Stavět ", 2)
        ]),
        (_unref(playerStore).player?.hero)
          ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(inventoryStore).open = !_unref(inventoryStore).open)),
                class: _normalizeClass(["btn btn-sm", _unref(inventoryStore).open ? 'btn-success' : 'btn-secondary'])
              }, " 💼 Inventář ", 2)
            ]))
          : _createCommentVNode("", true),
        (_unref(playerStore).current_region)
          ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
              (_unref(playerStore).current_region.owner_id !== _unref(playerStore).player.id)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: _normalizeClass(["btn btn-sm btn-secondary", {disabled: !_unref(playerStore).canOccupyCurrentRegion}]),
                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (occupyRegion()), ["prevent"]))
                  }, [
                    _createTextVNode(_toDisplayString(_unref(playerStore).current_region.owner_id ? '⚔️ Vybojovat' : '👑 Ovládnout') + " - ", 1),
                    _createElementVNode("strong", null, _toDisplayString(_unref(playerStore).current_region.name), 1)
                  ], 2))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("li", _hoisted_9, "state: " + _toDisplayString(_unref(state)), 1)
      ])
    ]),
    (_unref(playerStore).resources)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("ul", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(playerStore).resources, (resource) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "list-inline-item",
                title: resource.type.name
              }, [
                _createElementVNode("span", _hoisted_13, [
                  (resource.type.slug === 'food')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, "🍎"))
                    : _createCommentVNode("", true),
                  (resource.type.slug === 'wood')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, "🪵"))
                    : _createCommentVNode("", true),
                  (resource.type.slug === 'clay')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, "💩"))
                    : _createCommentVNode("", true),
                  (resource.type.slug === 'stone')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, "🪨"))
                    : _createCommentVNode("", true),
                  (resource.type.slug === 'iron')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, "⚙️"))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(resource.amount), 1)
                ])
              ], 8, _hoisted_12))
            }), 256))
          ])
        ]))
      : _createCommentVNode("", true),
    (!_unref(playerStore).position)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _cache[8] || (_cache[8] = _createTextVNode(" Nepodařilo se získat polohu, zkontroluj si nastavení prohlížeče. ")),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (reloadPage())),
            class: "btn btn-sm btn-primary"
          }, " Znovu načíst stránku ")
        ]))
      : _createCommentVNode("", true),
    (_unref(playerStore).position)
      ? (_openBlock(), _createBlock(_unref(LMap), {
          key: 2,
          ref_key: "map",
          ref: map,
          zoom: _unref(zoom),
          "onUpdate:zoom": _cache[6] || (_cache[6] = ($event: any) => (_isRef(zoom) ? (zoom).value = $event : null)),
          bounds: _unref(bounds),
          "onUpdate:bounds": _cache[7] || (_cache[7] = ($event: any) => (_isRef(bounds) ? (bounds).value = $event : null)),
          "min-zoom": 1,
          class: "map-element"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(LTileLayer), {
              url: url,
              "layer-type": "base",
              attribution: attribution
            }),
            _createVNode(_unref(LGeoJson), {
              ref_key: "gjLayer",
              ref: gjLayer,
              geojson: _unref(mapStore).geoJson,
              "options-style": setGeoJSONStyle,
              options: {pointToLayer: pointToLayer},
              onClick: mapClicked
            }, null, 8, ["geojson", "options"]),
            (_unref(playerStore).position)
              ? (_openBlock(), _createBlock(_unref(LCircle), {
                  key: 0,
                  "lat-lng": _unref(playerStore).position.latLng,
                  radius: 1,
                  "fill-opacity": 1,
                  "stroke-opacity": 1
                }, null, 8, ["lat-lng"]))
              : _createCommentVNode("", true),
            (_unref(playerStore).position)
              ? (_openBlock(), _createBlock(_unref(LCircle), {
                  key: 1,
                  "lat-lng": _unref(playerStore).position.latLng,
                  radius: 50,
                  "fill-opacity": 0.1,
                  "stroke-opacity": 0.3
                }, null, 8, ["lat-lng"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["zoom", "bounds"]))
      : _createCommentVNode("", true),
    (_unref(playerStore).position && map.value)
      ? (_openBlock(), _createBlock(BuildModal, {
          key: 3,
          open: _unref(state) === 'build',
          map: map.value
        }, null, 8, ["open", "map"]))
      : _createCommentVNode("", true),
    _createVNode(MapInfoModal)
  ]))
}
}

})
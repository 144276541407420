import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-6" }
const _hoisted_3 = { class: "card mb-3" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  key: 0,
  class: "accordion",
  id: "my_region_buildings"
}
const _hoisted_6 = { class: "accordion-item" }
const _hoisted_7 = ["id"]
const _hoisted_8 = ["data-bs-target", "aria-controls"]
const _hoisted_9 = { class: "text-black-50" }
const _hoisted_10 = ["id", "aria-labelledby"]
const _hoisted_11 = { class: "accordion-body" }
const _hoisted_12 = {
  class: "accordion",
  id: "my_buildings"
}
const _hoisted_13 = { class: "accordion-item" }
const _hoisted_14 = ["id"]
const _hoisted_15 = ["data-bs-target", "aria-controls"]
const _hoisted_16 = { class: "text-black-50" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 2 }
const _hoisted_20 = ["id", "aria-labelledby"]
const _hoisted_21 = { class: "accordion-body" }
const _hoisted_22 = {
  key: 0,
  class: "text-uppercase"
}
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { key: 2 }
const _hoisted_25 = { key: 3 }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = {
  key: 0,
  class: "list-unstyled"
}
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { class: "col-md-6" }
const _hoisted_30 = { class: "card mb-3" }
const _hoisted_31 = { class: "card-body" }
const _hoisted_32 = {
  key: 0,
  class: "list-unstyled"
}

import {onMounted} from "vue";
import Loading from "../components/Loading.vue";

import {useBuildingStore} from "../store/building";
import {useRegionStore} from "../store/region";
import {BuildingState} from "../type";
import Timer from "../components/Timer.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardView',
  setup(__props) {


const buildingStore = useBuildingStore();
const regionStore = useRegionStore();

onMounted(() => {
	if (buildingStore.buildings === undefined) {
		buildingStore.fetchBuildings();
	}

	if (regionStore.playerRegions === undefined) {
		regionStore.fetchPlayerRegions();
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[7] || (_cache[7] = _createElementVNode("h1", null, "Přehled", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("h3", { class: "card-title" }, "Budovy")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            (_unref(buildingStore).buildings)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(buildingStore).regionsBuildings, ({region, buildings}, regionId) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("h2", {
                        class: "accordion-header",
                        id: 'region_heading-' + regionId
                      }, [
                        _createElementVNode("button", {
                          class: "accordion-button collapsed",
                          type: "button",
                          "data-bs-toggle": "collapse",
                          "data-bs-target": '#region_buildings-' + regionId,
                          "aria-expanded": "false",
                          "aria-controls": 'region_buildings-' + regionId
                        }, [
                          _createElementVNode("strong", null, _toDisplayString(region.name), 1),
                          _cache[0] || (_cache[0] = _createTextVNode(" ")),
                          _createElementVNode("span", _hoisted_9, "( " + _toDisplayString(buildings.length) + " " + _toDisplayString(buildings.length === 1 ? 'budova' : (buildings.length < 5 ? 'budovy' : 'budov')) + " )", 1)
                        ], 8, _hoisted_8)
                      ], 8, _hoisted_7),
                      _createElementVNode("div", {
                        id: 'region_buildings-' + regionId,
                        class: "accordion-collapse collapse",
                        "aria-labelledby": 'region_buildings-' + regionId,
                        "data-bs-parent": "#my_region_buildings"
                      }, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(buildings, (building) => {
                              return (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createElementVNode("h2", {
                                  class: "accordion-header",
                                  id: 'building_heading-' + building.uuid
                                }, [
                                  _createElementVNode("button", {
                                    class: "accordion-button collapsed",
                                    type: "button",
                                    "data-bs-toggle": "collapse",
                                    "data-bs-target": '#building_detail-' + building.uuid,
                                    "aria-expanded": "false",
                                    "aria-controls": 'building_detail-' + building.uuid
                                  }, [
                                    _createElementVNode("strong", null, _toDisplayString(building.name), 1),
                                    _cache[2] || (_cache[2] = _createTextVNode("   ")),
                                    _createElementVNode("span", _hoisted_16, [
                                      _createTextVNode(" ( " + _toDisplayString(building.type.name) + " ", 1),
                                      (building.state === _unref(BuildingState).BUILDING)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, " - ve výstavbě"))
                                        : (building.state === _unref(BuildingState).DEMOLISHING)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, " - bourá se"))
                                          : (building.state === _unref(BuildingState).DEMOLISHED)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_19, " - zbouráno"))
                                            : _createCommentVNode("", true),
                                      _cache[1] || (_cache[1] = _createTextVNode(") "))
                                    ])
                                  ], 8, _hoisted_15)
                                ], 8, _hoisted_14),
                                _createElementVNode("div", {
                                  id: 'building_detail-' + building.uuid,
                                  class: "accordion-collapse collapse",
                                  "aria-labelledby": 'building_heading-' + building.uuid,
                                  "data-bs-parent": "#my_buildings"
                                }, [
                                  _createElementVNode("div", _hoisted_21, [
                                    _createElementVNode("div", null, [
                                      (building.state === _unref(BuildingState).BUILDING)
                                        ? (_openBlock(), _createElementBlock("strong", _hoisted_22, "ve výstavbě"))
                                        : (building.state === _unref(BuildingState).DEMOLISHING)
                                          ? (_openBlock(), _createElementBlock("strong", _hoisted_23, "bourá se"))
                                          : (building.state === _unref(BuildingState).DEMOLISHED)
                                            ? (_openBlock(), _createElementBlock("strong", _hoisted_24, "zbouráno"))
                                            : _createCommentVNode("", true),
                                      (building.state_valid_to)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_25, [
                                            _cache[3] || (_cache[3] = _createTextVNode(" - ")),
                                            _createVNode(Timer, {
                                              "end-date": new Date(building.state_valid_to)
                                            }, null, 8, ["end-date"])
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    (building.state === _unref(BuildingState).BUILT)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                          _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Sklad", -1)),
                                          (building.stock?.length > 0)
                                            ? (_openBlock(), _createElementBlock("ul", _hoisted_27, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(building.stock, (stock) => {
                                                  return (_openBlock(), _createElementBlock("li", null, _toDisplayString(stock.type.name) + ": " + _toDisplayString(stock.amount), 1))
                                                }), 256))
                                              ]))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_28, " prázdný"))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 8, _hoisted_20)
                              ]))
                            }), 256))
                          ])
                        ])
                      ], 8, _hoisted_10)
                    ]))
                  }), 256))
                ]))
              : (_openBlock(), _createBlock(Loading, { key: 1 }))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("h3", { class: "card-title" }, "Ovládnuté regiony")
          ], -1)),
          _createElementVNode("div", _hoisted_31, [
            (_unref(regionStore).playerRegions)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_32, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(regionStore).playerRegions, (region) => {
                    return (_openBlock(), _createElementBlock("li", null, [
                      _createElementVNode("strong", null, _toDisplayString(region.name), 1)
                    ]))
                  }), 256))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 64))
}
}

})